import { Route } from "wouter";
import { TopNav } from "../components/TopNav";
import { flexboxCss } from "../styles/layout";
import { SyncClientController } from "../components/SyncClientController";
import { Footer } from "../components/Footer";

export const WebIntentSyncRoute = () => (
  <Route path="/webintent/sync/:hostId">
    {(params) => (
      <div css={flexboxCss({ direction: "column", height: "100vh" })}>
        <TopNav />
        <SyncClientController hostId={params.hostId} />
        <Footer />
      </div>
    )}
  </Route>
);
