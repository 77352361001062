import { Route } from "wouter";
import { TopNav } from "../components/TopNav";
import { flexboxCss } from "../styles/layout";
import { SyncHostController } from "../components/SyncHostController";
import { Footer } from "../components/Footer";

export const InitiateSyncRoute = () => (
  <Route path="/">
    {(params) => (
      <div css={flexboxCss({ direction: "column", height: "100vh" })}>
        <TopNav />
        <SyncHostController />
        <Footer />
      </div>
    )}
  </Route>
);
