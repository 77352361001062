import { FC } from "react";
import { flexboxCss } from "../styles/layout";

export const Footer: FC = () => (
  <footer
    css={flexboxCss({
      align: "center",
      justify: "center",
      height: "min-content",
      padding: "10px",
    })}
  >
    <small data-color={500}>&copy; Copyright Caleb Peterson</small>
  </footer>
);
