import { ChangeEvent, FC, Fragment, useCallback, useState } from "react";
import useClipboard from "react-use-clipboard";
import { flexboxCss } from "../styles/layout";
import { QRCodeSVG } from "qrcode.react";
import colors from "../styles/colors";
import { useSyncHost } from "react-p2p";
import { Spinner } from "./Spinner";
import { css } from "@emotion/react";

const QR_CODE_SIZE = 256;

const instructionsCss = css`
  text-align: center;
`;

const qrContainerCss = css`
  background-color: ${colors.slate[50]};
  padding: 10px;
  border-radius: 5px;
  width: ${QR_CODE_SIZE}px;
  height: ${QR_CODE_SIZE}px;
`;

export const SyncHostController: FC = () => {
  const [fileBlob, setFileBlob] = useState<Blob | undefined>();
  const [filename, setFilename] = useState<string | undefined>();
  const [fileType, setFileType] = useState<string | undefined>();

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          const blob = new Blob([reader.result], { type: file.type });
          setFileBlob(blob);
          setFilename(file.name);
          setFileType(file.type);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const onSyncSerialize = useCallback(async () => {
    if (!fileBlob) {
      throw new Error(`No file provided for sharing.`);
    }

    return { blob: fileBlob, filename, fileType };
  }, [fileBlob, filename, fileType]);

  const { hostId, state, error } = useSyncHost(onSyncSerialize);

  // eslint-disable-next-line no-restricted-globals
  const url = `${location.protocol}//${location.host}/webintent/sync/${hostId}`;

  const [isCopied, setCopied] = useClipboard(url, {
    successDuration: 5000,
  });

  const content =
    fileBlob === undefined ? (
      <div>
        <input type="file" onChange={onChangeFile} />
      </div>
    ) : state === "starting" || state === "sending" ? (
      <Spinner reason={state} />
    ) : state === "serializing" ? (
      <Spinner reason="preparing" />
    ) : state === "error" ? (
      <pre>{error.toString()}</pre>
    ) : (
      <Fragment>
        <div css={instructionsCss} data-color={400}>
          Scan the code with the recipient's
          <br />
          phone or copy and share the link.
        </div>
        <div css={qrContainerCss}>
          <QRCodeSVG
            value={url}
            size={QR_CODE_SIZE}
            fgColor={colors.slate[800]}
            bgColor={colors.slate[50]}
          />
        </div>
        <button type="button" onClick={setCopied}>
          {isCopied ? "Link copied" : "Copy link to clipboard"}
        </button>
      </Fragment>
    );

  return (
    <div
      css={flexboxCss({
        direction: "column",
        align: "center",
        justify: "center",
        gap: "10px",
        height: "100%",
      })}
    >
      {content}
    </div>
  );
};
