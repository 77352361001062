import { FC, useCallback, useState } from "react";
import { useSyncClient } from "react-p2p";
import { saveAs } from "file-saver";
import { Spinner } from "./Spinner";
import { flexboxCss } from "../styles/layout";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import { css } from "@emotion/react";

interface Props {
  hostId: string;
}

const layoutCss = css`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const SyncClientController: FC<Props> = ({ hostId }) => {
  const [blob, setBlob] = useState<Blob | undefined>();
  const [filename, setFilename] = useState<string | undefined>();

  const onSyncReceive = useCallback(
    async (blob: Blob, filename: string | undefined) => {
      setBlob(blob);
      setFilename(filename);
    },
    []
  );

  const { state, error } = useSyncClient(hostId, onSyncReceive);

  const onSave = useCallback(() => {
    if (blob) {
      saveAs(blob, filename);
    }
  }, [blob, filename]);

  if (state === "done") {
    return (
      <div
        css={flexboxCss({
          direction: "column",
          justify: "center",
          align: "center",
          gap: "10px",
        })}
      >
        <div css={layoutCss} data-color={400}>
          <Icon path={mdiCheckCircleOutline} size={1} />
          File has been received.
        </div>
        <button onClick={onSave}>Save to downloads</button>
      </div>
    );
  }

  if (state === "error") {
    return (
      <div
        css={flexboxCss({
          direction: "column",
          justify: "center",
          align: "center",
          gap: "10px",
        })}
      >
        <div css={layoutCss}>
          <pre>{error.toString()}</pre>
        </div>
      </div>
    );
  }

  return <Spinner reason={state} />;
};
