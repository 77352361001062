import React, { FC } from "react";
import { globalCss } from "./styles/globals";
import { Global } from "@emotion/react";
import { InitiateSyncRoute } from "./routes/InitiateSyncRoute";
import { WebIntentSyncRoute } from "./routes/WebIntentSyncRoute";

const App: FC = () => (
  <div>
    <Global styles={globalCss} />
    <InitiateSyncRoute />
    <WebIntentSyncRoute />
  </div>
);

export default App;
